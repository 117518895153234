body {
  background-color: #0a0a0a;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}
