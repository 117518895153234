/* MAIN CONTAINER */
.container {
    width: 80vw;
    display: flex;
    justify-content: space-between;
    background: #1b1b1b;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.1);
    overflow: hidden;
    margin: 40px auto;
    padding: 40px;
}

/* Left Section: Business Info */
.business-info {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 20px;
}

.business-name {
    font-size: 48px;
    font-weight: bold;
    text-transform: uppercase;
    color: #00e6e6;
    letter-spacing: 2px;
    margin-bottom: 15px;
}

/* Business Image (for Mobile Only) */
.business-image-mobile {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 10px;
    margin-bottom: 15px;
    display: none; /* Hidden on Desktop */
}

.business-description {
    font-size: 18px;
    color: #ddd;
    line-height: 1.6;
    margin-bottom: 20px;
}

.business-contact {
    font-size: 16px;
    color: #ddd;
    margin-bottom: 20px;
}

.business-contact strong {
    color: #00e6e6;
}

.business-contact a {
    color: #00c6ff;
    text-decoration: none;
    font-weight: bold;
}

.business-contact a:hover {
    text-decoration: underline;
}

.map-container {
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
}

iframe {
    width: 100%;
    height: 250px;
    border: none;
    border-radius: 10px;
}

/* Right Section: Business Image (for Desktop Only) */
.business-image-desktop {
    width: 50%;
    background-size: cover;
    background-position: center;   
    border-radius: 20px;
}

.business-image-desktop img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

/* Buttons */
.action-buttons {
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
}

.btn {
    width: 100%;
    padding: 15px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    border-radius: 8px;
    transition: 0.3s;
    display: inline-block;
}

.btn-directions {
    background: linear-gradient(90deg, #0072ff, #00c6ff);
    color: white;
    box-shadow: 0px 0px 15px rgba(0, 214, 255, 0.5);
}

.btn-directions:hover {
    background: linear-gradient(90deg, #00c6ff, #0072ff);
}

/* MOBILE FIX */
@media (max-width: 1024px) {
    .container {
        flex-direction: column;
        width: 90%;
        padding: 20px;
        align-items: center;
    }

    .business-info, .business-image-desktop {
        width: 100%;
        text-align: center;
        margin-right: 0;
    }

    /* Ensure correct stacking order */
    .business-name {
        order: 1;
    }

    .business-image-mobile {
        order: 2;
        display: block; /* Show only on mobile */
    }

    .business-image-desktop {
        display: none; /* Hide on mobile */
    }

    .business-description {
        order: 3;
    }

    .business-contact {
        order: 4; /* Change this to 3 to move it below description */
    }

    .map-container {
        order: 5;
    }

    .action-buttons {
        order: 6;
    }
}