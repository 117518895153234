.header {
    width: 100%;
    background: #111;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(0, 255, 255, 0.2);
}

.header h1 {
    font-size: 28px;
    font-weight: bold;
    text-transform: uppercase;
    color: #00e6e6;
    letter-spacing: 2px;
}

.nav-links {
    display: flex;
    gap: 20px;
}

.nav-links a {
    color: #ddd;
    text-decoration: none;
    font-size: 16px;
    transition: 0.3s;
}

.nav-links a:hover {
    color: #00e6e6;
}

/* Responsive */
@media (max-width: 768px) {
    .header {
        flex-direction: column;
        text-align: center;
    }

    .nav-links {
        margin-top: 10px;
    }
}