.homepage-container {
    width: 80vw;
    background: #1b1b1b;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.1);
    overflow: hidden;
    margin: 40px auto;
    padding: 40px;
}

.hero-section {
    background: #222;
    padding: 50px;
    margin: 20px 0;
    border-radius: 8px;
}

.hero-section h2 {
    font-size: 36px;
    margin-bottom: 10px;
}

.hero-section p {
    font-size: 18px;
    color: #bbb;
}

.features-section {
    padding: 40px 0;
}

.features {
    display: flex;
    justify-content: space-around;
    gap: 20px;
}

.feature-box {
    background: #333;
    padding: 20px;
    border-radius: 8px;
    width: 30%;
}

.why-adanera {
    background: #222;
    padding: 40px;
    margin: 20px 0;
    border-radius: 8px;
}

.why-adanera ul {
    list-style: none;
    padding: 0;
}

.why-adanera li {
    font-size: 18px;
    margin: 10px 0;
}

.cta-section {
    background: #333;
    padding: 40px;
    border-radius: 8px;
}

.app-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.app-links img {
    height: 50px;
}

.footer {
    text-align: center;
    padding: 20px;
    border-top: 2px solid #0ff;
    margin-top: 20px;
}
